<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
        >
          <b-card-body>

            <validation-observer ref="validationRules">
              <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <!-- Template name -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('TEMPLATE_NAME')"
                      label-for="id-template-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('TEMPLATE_NAME')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-template-name"
                          v-model="template.name"
                          maxlength="300"
                          :placeholder="$t('TEMPLATE_NAME')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Referance ID -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('REFERENCE_ID')"
                      label-for="id-reference-number"
                    >
                      <b-form-input
                        id="id-reference-number"
                        v-model="template.reference_id"
                        maxlength="45"
                        :placeholder="$t('REFERENCE_ID')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: Category -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('CATEGORY')"
                      label-for="id-category"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('CATEGORY')"
                        rules="required"
                      >
                        <v-select
                          id="id-category"
                          v-model="category"
                          :placeholder="$t('SELECT_CATEGORY')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="categoryOptions"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>
                  <!-- Language -->
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('LANGUAGE')"
                      label-for="id-language"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('LANGUAGE')"
                        rules="required"
                      >
                        <v-select
                          id="id-language"
                          v-model="language"
                          :options="languageOptions"
                          label="name"
                          :clearable="false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>
                  <!-- Description -->
                  <b-col
                    lg="12"
                    md="12"
                  >
                    <b-form-group
                      :label="$t('DESCRIPTION')"
                      label-for="task-description"
                    >
                      <quill-editor
                        id="quil-content"
                        v-model="template.description"
                        :options="editorOption"
                        class="border-bottom-0"
                      />
                      <div
                        id="quill-toolbar"
                        class="d-flex justify-content-end border-top-0"
                      >
                        <!-- Add a bold button -->
                        <button class="ql-bold" />
                        <button class="ql-italic" />
                        <button class="ql-underline" />
                        <button class="ql-align" />
                        <button class="ql-link" />
                      </div>
                    </b-form-group>

                  </b-col>
                  <!-- Field: Game -->
                  <b-col
                    v-if="selectedTemplateType===templateType.GAME"
                    lg="6"
                    md="6"
                  >

                    <b-form-group
                      :label="$t('GAME_NAME')"
                      label-for="id-game"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('GAME_NAME')"
                        rules="required"
                      >
                        <v-select
                          id="id-game"
                          v-model="competition"
                          :placeholder="$t('SELECT_GAME')"
                          label="c_string_swe"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="competitions"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Survey -->
                  <b-col
                    v-if="selectedTemplateType===templateType.SURVEY"
                    lg="6"
                    md="6"
                  >

                    <b-form-group
                      :label="$t('Survey')"
                      label-for="id-survey"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Survey')"
                        rules="required"
                      >
                        <v-select
                          id="id-survey"
                          v-model="survey"
                          :placeholder="$t('SELECT_SURVEY')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="surveys"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Scope name -->
                  <b-col
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('SCOPE')"
                      label-for="id-scope"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('SCOPE')"
                        rules="required"
                      >
                        <v-select
                          id="id-scope"
                          v-model="scope"
                          placeholder="Select Scope"
                          :clearable="false"
                          class="flex-grow-1"
                          label="name"
                          :options="scopeOptions"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>
                  <!-- Submit and reset -->
                  <b-col cols="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ $t('BUTTON.SUBMIT') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                    >
                      {{ $t('BUTTON.RESET') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BButton, BForm,
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import { ref } from '@vue/composition-api'
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    // 3rd party packages
    quillEditor,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      template: {
        reference_id: '',
        name: '',
        is_private: 0,
        category_id: '',
        companyId: 0,
        description: '',
      },
      category: '',
      categoryOptions: [],
      competitions: [],
      competition: '',
      surveys: [],
      survey: '',
      scope: '',
      selectedTemplateType: '',
      scopeOptions: constants.SCOPE,
      language: '',
      languageOptions: constants.LANGUAGES,
      templateType: constants.TEMPLATE_TYPE,
      required,
    }
  },
  setup() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your template description',
    }

    const currentPage = ref(1)

    const {
      successMessage,
      errorMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
      currentPage,
      editorOption,
    }
  },
  created() {
    this.fetchCategory()
    if (router.currentRoute.query.object) {
      this.selectedTemplateType = router.currentRoute.query.object
      if (this.selectedTemplateType === constants.TEMPLATE_TYPE.GAME) {
        this.fetchGames()
      } else {
        this.fetchSurveys()
      }
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters('template', ['getAllCategories']),
    ...mapGetters('game', ['getAllGames']),
  },
  methods: {
    ...mapActions('template', ['fetchAllCategories', 'createTemplateByGame']),
    ...mapActions('game', ['fetchAllGames']),
    ...mapActions('survey', ['fetchAllSurveys']),
    fetchSurveys() {
      const payload = {
        page: this.currentPage,
      }
      try {
        this.fetchAllSurveys(payload).then(response => {
          if (response) {
            this.surveys = response.allResult
            if (router.currentRoute.params.id) {
              this.survey = this.surveys.find(i => i.ID === router.currentRoute.params.id)
            }
          }
        })
      } catch (error) {
        this.showErrorMessage()
      }
    },
    fetchCategory() {
      this.fetchAllCategories().then(response => {
        if (response) {
          this.categoryOptions = this.getAllCategories
        }
      })
    },
    fetchGames() {
      this.fetchAllGames().then(() => {
        this.competitions = this.getAllGames
        if (router.currentRoute.params.id) {
          this.competition = this.competitions.find(i => i.encrypt_id === router.currentRoute.params.id)
        }
      })
    },
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.template.category_id = this.category.ID
          this.template.is_private = this.scope.id
          this.template.language = this.language.id
          if (this.selectedTemplateType === constants.TEMPLATE_TYPE.GAME) {
            const payload = {
              data: this.template,
              key: this.competition.ID,
            }
            this.createTemplateByGame(payload).then(() => {
              this.successMessage(this.$i18n.t('MESSAGE.TEMPLATE_CREATED'))
              this.$router.push({ name: 'game-list' })
            }).catch(error => {
              if (error.response.data.status === 'failed') {
                this.errorMessage(error.response.data.message.toString())
              }
            })
          }
        }
      })
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/quill.scss';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
