var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"invoice-add-wrapper"},[_c('b-row',{staticClass:"invoice-add"},[_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('validation-observer',{ref:"validationRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('TEMPLATE_NAME'),"label-for":"id-template-name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('TEMPLATE_NAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-template-name","maxlength":"300","placeholder":_vm.$t('TEMPLATE_NAME')},model:{value:(_vm.template.name),callback:function ($$v) {_vm.$set(_vm.template, "name", $$v)},expression:"template.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('REFERENCE_ID'),"label-for":"id-reference-number"}},[_c('b-form-input',{attrs:{"id":"id-reference-number","maxlength":"45","placeholder":_vm.$t('REFERENCE_ID')},model:{value:(_vm.template.reference_id),callback:function ($$v) {_vm.$set(_vm.template, "reference_id", $$v)},expression:"template.reference_id"}})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CATEGORY'),"label-for":"id-category"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CATEGORY'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-category","placeholder":_vm.$t('SELECT_CATEGORY'),"label":"name","clearable":false,"options":_vm.categoryOptions},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('LANGUAGE'),"label-for":"id-language"}},[_c('validation-provider',{attrs:{"name":_vm.$t('LANGUAGE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"id-language","options":_vm.languageOptions,"label":"name","clearable":false},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('DESCRIPTION'),"label-for":"task-description"}},[_c('quill-editor',{staticClass:"border-bottom-0",attrs:{"id":"quil-content","options":_vm.editorOption},model:{value:(_vm.template.description),callback:function ($$v) {_vm.$set(_vm.template, "description", $$v)},expression:"template.description"}}),_c('div',{staticClass:"d-flex justify-content-end border-top-0",attrs:{"id":"quill-toolbar"}},[_c('button',{staticClass:"ql-bold"}),_c('button',{staticClass:"ql-italic"}),_c('button',{staticClass:"ql-underline"}),_c('button',{staticClass:"ql-align"}),_c('button',{staticClass:"ql-link"})])],1)],1),(_vm.selectedTemplateType===_vm.templateType.GAME)?_c('b-col',{attrs:{"lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('GAME_NAME'),"label-for":"id-game"}},[_c('validation-provider',{attrs:{"name":_vm.$t('GAME_NAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-game","placeholder":_vm.$t('SELECT_GAME'),"label":"c_string_swe","clearable":false,"options":_vm.competitions},model:{value:(_vm.competition),callback:function ($$v) {_vm.competition=$$v},expression:"competition"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,394778303)})],1)],1):_vm._e(),(_vm.selectedTemplateType===_vm.templateType.SURVEY)?_c('b-col',{attrs:{"lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Survey'),"label-for":"id-survey"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Survey'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-survey","placeholder":_vm.$t('SELECT_SURVEY'),"label":"name","clearable":false,"options":_vm.surveys},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1037635215)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('SCOPE'),"label-for":"id-scope"}},[_c('validation-provider',{attrs:{"name":_vm.$t('SCOPE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-scope","placeholder":"Select Scope","clearable":false,"label":"name","options":_vm.scopeOptions},model:{value:(_vm.scope),callback:function ($$v) {_vm.scope=$$v},expression:"scope"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('BUTTON.SUBMIT'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t('BUTTON.RESET'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }